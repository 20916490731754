import React from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';
import theme from 'prism-react-renderer/themes/palenight';

const Code = ({ codeString, ...props }) => (
  <Highlight
    {...defaultProps}
    code={codeString}
    language="javascript"
    theme={theme}
  >
    {({
      className, tokens, getLineProps, getTokenProps,
    }) => (
      <pre className={`${className} bg-cloud-700 tracking-wide `}>
        {tokens.map((line, i) => (
          <div {...getLineProps({ line, key: i })}>
            <span className="w-16 mr-12 text-gray-500 text-base text-right">
              {i < 9 && ' '}
              {i + 1}
            </span>
            {line.map((token, key) => (
              <span {...getTokenProps({ token, key })} />
            ))}
          </div>
        ))}
      </pre>
    )}
  </Highlight>
);

export default Code;
