import React from 'react';
import Container from './container';

import Code from './code';
import subscribe from '../images/subscribe.svg';
import Projects from './projects';

const codeString = `import WebDeveloper from "./components/webDeveloper"

<WebDeveloper 
  name ="Akshay Thakur"
  location ="Hamirpur (H.P.)"
  hobby="SpeedCubing"
/>`;

const codeString2 = `// Work information
const profession = "Frontend Engineer";

// Skills
const skills = [
  "HTML 5",
  "CSS 3",
  "React JS",
  "Gatsby JS",
  "Next JS",
  "GraphQL",
  "Tailwind CSS",
  "Bulma",
  "GitHub",
  "Bootstrap"
];`;

// About Me
// I am a tech enthusiast and spend a large amount of my free
// time exploring the latest tech advancements.
// I love Open Source Software.
// I love dark mode because light attracts bugs 😂
// Apart from being a web developer, I enjoy speedcubing
// and can solve a Rubik's cube in under 40 seconds.

const CodeEditor = ({ codeString }) => (
  <div className="w-full overflow-hidden rounded-lg shadow-2xl xl:w-2/3 ">
    <div className="flex items-center justify-between w-full p-3 bg-gray-700">
      <div className="flex items-center space-x-2">
        <div className="w-4 h-4 bg-red-300 border border-red-400 rounded-full" />
        <div className="w-4 h-4 bg-yellow-300 border border-yellow-400 rounded-full" />
        <div className="w-4 h-4 bg-green-300 border border-green-400 rounded-full" />
      </div>
    </div>
    <pre className="w-full p-5 overflow-auto leading-5 break-all whitespace-pre-wrap select-text lg:pl-12 xl:text-lg font-code bg-cloud-700">
      <Code codeString={codeString} />
    </pre>
  </div>
);

const Skills = () => (
  <div className="text-white bg-indigo py-14">
    <Container>
      <div className="flex flex-col-reverse items-center py-20 pt-40 -mx-4 -mt-40 md:pt-20 xl:-mt-36 md:mx-0 xl:flex-row justify-items-center ">
        <CodeEditor codeString={codeString} />
        <h1 className="pb-2 pr-2 mb-2 text-3xl font-black leading-none tracking-tighter text-center xl:text-5xl xl:w-1/3">
          WHO I AM ?
        </h1>
      </div>
      <div className="flex flex-col items-center py-10 mt-10 -mx-4 md:py-20 xl:mt-28 md:mx-0 xl:flex-row justify-items-center ">
        <h1 className="pb-2 pr-2 mb-2 text-3xl font-black leading-none tracking-tighter text-center xl:text-5xl xl:w-1/3">
          WHAT I DO ?
        </h1>
        <CodeEditor codeString={codeString2} />
      </div>
      {/* TODO: coplete this section  */}
      {/* <section className=" body-font">
					<div className="container flex flex-col items-center px-5 py-24 mx-auto md:flex-row">
						<div className="flex flex-col items-center mb-16 text-center lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 md:items-start md:text-left md:mb-0">
							<h1 className="mb-4 text-3xl font-medium title-font sm:text-4xl ">
								Before they sold out
								<br className="hidden lg:inline-block" />
								readymade gluten
							</h1>
							<p className="mb-8 leading-relaxed">
								Copper mug try-hard pitchfork pour-over freegan
								heirloom neutra air plant cold-pressed tacos
								poke beard tote bag. Heirloom echo park mlkshk
								tote bag selvage hot chicken authentic tumeric
								truffaut hexagon try-hard chambray.
							</p>
						</div>
						<div className="w-5/6 lg:max-w-lg lg:w-full md:w-1/2">
							<img alt="hero" src={subscribe} />
						</div>
					</div>
				</section> */}

      {/* <Projects /> */}
    </Container>
  </div>
);

export default Skills;
