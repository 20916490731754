import React from 'react';

const Button = ({ children, className }) => (
  <button
    className={`${className} font-bold inline-flex items-center justify-center px-6 pt-4 pb-3  mt-auto  tracking-tight text-center text-white transition duration-500 ease-in-out transform rounded-lg cursor-pointer bg-cloud-800 lg:w-auto focus:outline-none focus:ring focus:ring-blue-600 focus:ring-opacity-50 hover:text-white `}
		>
    {children}
  </button>
);

export default Button;
