import React from 'react';

import Hero from '../components/hero';
import Layout from '../components/layout';

import SEO from '../components/seo';
import Skills from '../components/skills';
import ogImage from '../images/open_graph/og.png';

const IndexPage = () => (
  <Layout>
    <SEO
      title="Akshay"
      image={ogImage}
      description="I am passionate about creating web experiences that improve people's life. I focus on crafting clean and user-friendly experiences."
    />
    <Hero />
    <Skills />
  </Layout>
);

export default IndexPage;
