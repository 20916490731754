import { Link } from 'gatsby';
import React from 'react';
import Lottie from 'react-lottie';
import * as animationData from '../lotties/working-new.json';

import Button from './button';
import Container from './container';
import PrimaryButton from './primaryButton';

const Hero = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <section className="text-white hero bg-cloud-50 bg-gradient-to-br dark:from-cloud-700 dark:to-cloud-900 ">
      <Container className="flex flex-col items-center pt-20 md:flex-row ">
        <div className="flex flex-col items-center mb-16 text-center md:flex-grow lg:items-start md:mb-0 lg:text-left">
          <h2 className="w-full mx-auto mb-4 font-semibold tracking-widest text-gray-900 dark:text-gray-200">
            Frontend Web Developer
          </h2>
          <h1 className="pb-2 pr-1 mb-2 font-black leading-none tracking-tighter text-gradient text-8xl ">
            Hi, I am Akshay
          </h1>
          <p className="mb-12 text-xl leading-relaxed text-gray-600 dark:text-gray-300 lg:w-2/3">
            I am passionate about creating clean and user-friendly
            web experiences. I help businesses create products that
            people love.
          </p>
          <div className="flex flex-col justify-center space-x-3 sm:flex-row">
            <Link to="/resources" className="mb-3 lg:mr-4">
              <PrimaryButton>Get My Resources</PrimaryButton>
            </Link>

            <a
              href="https://twitter.com/awsm_akshay"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button className=" hover:bg-blue">
                {' '}
                <svg
                  fill="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-5 h-5 mr-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z" />
                </svg>
                {' '}
                Follow Me
              </Button>
            </a>
          </div>
        </div>
        <div className="hidden pb-20 lg:block">
          <Lottie options={defaultOptions} height={420} width={600} />
          {/* <img alt="hero" src={laptop} /> */}
        </div>
      </Container>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#7269D1"
          fillOpacity="1"
          d="M0,224L48,208C96,192,192,160,288,160C384,160,480,192,576,202.7C672,213,768,203,864,181.3C960,160,1056,128,1152,112C1248,96,1344,96,1392,96L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        />
      </svg>
    </section>
  );
};

export default Hero;
